import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/pages/Hanako-Japanese-Restaurant/114367055275003">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Hanako Sushi Facebook"
              />
            </CFLink>
            {/*<CFLink href="https://www.yelp.ca/biz/sushi-musa-langley-city">
              <CFImage w="40px" pr="10px" src={yelp} alt="Hanako Sushi Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Hanako Sushi Zomato"
              />
            </CFLink>*/}
            {/*<CFLink href="https://www.instagram.com/sushikyowa/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Hanako Sushi Instagram"
              />
            </CFLink>*/}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/pages/Hanako-Japanese-Restaurant/114367055275003">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Hanako Sushi Facebook"
                hover
              />
            </CFLink>
            {/*<CFLink href="https://www.yelp.ca/biz/sushi-musa-langley-city">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Hanako Sushi Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Hanako Sushi Zomato"
                hover
              />
            </CFLink>*/}
            {/* <CFLink href="https://www.instagram.com/sushikyowa/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Hanako Sushi Instagram"
                hover
              />
            </CFLink>*/}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
